import stockAdjust from '@/api/DrugManagement/stockAdjust'
import userRole from '@/api/userRole'
import footerDataTablessm from '@/components/basicComponents/footerDataTables-sm.json'
import footerDataTablessmEN from '@/components/basicComponents/footerDataTablesEN.json'
import { i18n } from '@/plugins/i18n'
import store from '@/store'
import { onMounted, ref, watch } from '@vue/composition-api'

export default function useCreateStockUpdate() {
  const searchtext = ref('')
  const options = ref({})
  const footer = ref(i18n.locale == 'en' ? footerDataTablessmEN : footerDataTablessm)
  const dataTableList = ref([])
  const totalDataTableList = ref(0)
  const loading = ref(false)
  const segmentId = ref(0)
  const userList = ref([])
  const user = ref('')
  const transfer_comment = ref('')

  const total = ref(0)

  const cartList = ref([])

  const isSelect = ref(false)
  const detailData = ref([])
  const selectedDrug = ref([])
  const detailData_list = ref([])
  const statusCheckCount = ref(false)
  const addCardLoading = ref(false)

  const isUserComfirm = ref(false)

  const loadingRemove = ref(false)
  const updateStockLoading = ref(false)

  const columns = ref([
    { text: '#', value: 'drug_id_pri', width: 30 },
    {
      text: i18n.t('type'),
      value: 'drug_category_id',
      align: 'center',
      width: 100,
    },
    { text: i18n.t('code'), value: 'drug_id', width: 80 },
    { text: i18n.t('name'), value: 'drug_name', width: 200 },
    {
      text: i18n.t('price'),
      value: 'drug_price',
      width: 150,
      align: 'end',
    },
    { text: i18n.t('unit'), value: 'drug_unit', width: 80 },
    {
      text: i18n.t('option'),
      value: 'actions',
      width: 100,
      align: 'center fixed',
    },
  ])

  const { userRoleList } = userRole

  const { drugList, cartList: getCartList, cartAdd, cartRemove, updateStock } = stockAdjust

  onMounted(() => {
    getUserRoleList()
    fetchDataTable()
    getDataCartList()
  })

  const confirmUpdateStock = userData => {
    updateStockLoading.value = true
    updateStock({
      transfer_label_id: 1,
      transfer_label_name: user.value.user_fullname,
      transfer_comment: `${transfer_comment.value} (${i18n.t('more_comment')}: ${userData.user_fullname})`,
      lang: i18n.locale,
    }).then(res => {
      store.commit('app/ALERT', {
        message: res.message,
        color: res.response ? 'success' : 'error',
      })
      fetchDataTable()
      getDataCartList()
      transfer_comment.value = ''
      searchtext.value = ''
      getUserRoleList()
      updateStockLoading.value = false
    })
  }

  const deleteDataDrug = id => {
    loadingRemove.value = true
    cartRemove(id).then(res => {
      store.commit('app/ALERT', {
        message: res.message,
        color: res.response ? 'success' : 'error',
      })
      loadingRemove.value = false
      fetchDataTable()
      getDataCartList()
    })
  }

  const addDataDrug = () => {
    addCardLoading.value = true
    let dataStatus = true
    selectedDrug.value.forEach(element => {
      if (parseFloat(element.transferorder_amount) > parseFloat(element.drugordert_total)) {
        dataStatus = false
      }
    })
    if (dataStatus == false) {
      store.commit('app/ALERT', {
        message: 'amountInputThenMoreBalance',
        color: 'error',
      })
      addCardLoading.value = false
    } else {
      const drugorder_id_pri = []
      const transferorder_amount = []
      selectedDrug.value.forEach(element => {
        drugorder_id_pri.push(element.drugorder_id_pri)
        transferorder_amount.push(element.transferorder_amount)
      })
      const body = {
        drugorder_id_pri,
        transferorder_amount,
      }
      cartAdd(body).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        fetchDataTable()
        getDataCartList()
        isSelect.value = false
        addCardLoading.value = false
      })
    }
  }

  const showDetail = item => {
    let i
    for (i = 0; i < item.drugorder.length; i++) {
      item.drugorder[i].transferorder_amount = 0
    }
    if (i == item.drugorder.length) {
      detailData.value = item
      detailData_list.value = item.drugorder
    }
    selectedDrug.value = []
    isSelect.value = true
  }

  const getDataCartList = () => {
    getCartList().then(res => {
      res.forEach(element => {
        total.value += +element.transferorder_cost
      })
      cartList.value = res
    })
  }
  const fetchDataTable = () => {
    loading.value = true
    drugList({
      searchtext: searchtext.value,
      druggroup_id: '',
      drug_category_id: '',
      active_page: options.value.page,
      per_page: options.value.itemsPerPage == -1 ? '0' : options.value.itemsPerPage,
    }).then(res => {
      const { data, count, segment } = res
      dataTableList.value = data
      totalDataTableList.value = count
      segmentId.value = segment
      loading.value = false
    })
  }
  const sum = (item, index) => {
    detailData_list.value[index] = item
    detailData_list.value = [...detailData_list.value]
    for (let i = 0; i < detailData_list.value.length; i++) {
      for (let j = 0; j < selectedDrug.value.length; j++) {
        if (detailData_list.value[i].drugorder_id_pri == selectedDrug.value[j].drugorder_id_pri) {
          if (
            selectedDrug.value[j].transferorder_amount != 0 &&
            selectedDrug.value[j].transferorder_amount != '' &&
            +selectedDrug.value[j].transferorder_amount >= 1 &&
            selectedDrug.value[j].transferorder_amount != null
          ) {
            statusCheckCount.value = true
          } else {
            statusCheckCount.value = false
            break
          }
        }
      }
    }
  }
  const getUserRoleList = () => {
    userRoleList({
      role_id: ['1', '3'],
    }).then(res => {
      userList.value = res
      user.value = userList.value.find(item => item.user_id == localStorage.getItem('user_id'))
    })
  }
  watch([options, searchtext], (newvalue, oldvalue) => {
    if (newvalue[1] !== oldvalue[1]) {
      options.value.page = 1
    }
    fetchDataTable()
  })
  watch(
    () => selectedDrug.value,
    () => {
      for (let i = 0; i < selectedDrug.value.length; i++) {
        if (
          selectedDrug.value[i].transferorder_amount != 0 &&
          selectedDrug.value[i].transferorder_amount != '' &&
          +selectedDrug.value[i].transferorder_amount >= 1 &&
          selectedDrug.value[i].transferorder_amount != null
        ) {
          statusCheckCount.value = true
        } else {
          statusCheckCount.value = false
          break
        }
      }
    },
  )

  return {
    isUserComfirm,
    addCardLoading,
    statusCheckCount,
    detailData_list,
    selectedDrug,
    detailData,
    isSelect,
    cartList,
    options,
    footer,
    dataTableList,
    totalDataTableList,
    loading,
    loadingRemove,
    segmentId,
    columns,
    searchtext,
    userList,
    user,
    transfer_comment,
    updateStockLoading,
    confirmUpdateStock,
    deleteDataDrug,
    sum,
    addDataDrug,
    showDetail,
  }
}
