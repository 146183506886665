<template>
  <div>
    <v-card>
      <v-card-title class="px-2">
        <router-link
          class="font-weight-medium text-decoration-none me-2"
          :to="{ name: 'Stock-update-list'}"
        >
          <v-tooltip
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                v-bind="attrs"
                icon
                v-on="on"
              >
                <v-icon>{{ icons.mdiArrowLeftBold }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("back") }}</span>
          </v-tooltip>
        </router-link> {{ $t('Create stock update list') }}
      </v-card-title>

      <v-row class="px-5 mb-2">
        <v-col
          cols="12"
          lg="6"
        >
          <v-text-field
            v-model="searchtext"
            outlined
            :label="$t('search')"
            :placeholder="$t('wordingSearch')"
            dense
          ></v-text-field>
          <v-data-table
            :headers="columns"
            :items="dataTableList"
            :options.sync="options"
            :server-items-length="totalDataTableList"
            :footer-props="footer"
            :loading="loading"
            mobile-breakpoint="0"
            disable-sort
            :loading-text="$t('data_loading')"
            :no-data-text="$t('no_information')"
          >
            <template #[`item.drug_id_pri`]="{ index }">
              {{ segmentId + index + 1 }}
            </template>
            <template #[`item.drug_category_id`]="{ item }">
              <DrugStatus :type="item.drug_category_id" />
            </template>
            <template #[`item.actions`]="{ item }">
              <v-tooltip
                color="#212121"
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    icon
                    color="primary"
                    @click="showDetail(item)"
                    v-on="on"
                  >
                    <v-icon>{{ icons.mdiCheckCircleOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("select") }}</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
        <v-col
          cols="12"
          lg="6"
        >
          <v-autocomplete
            v-model="user"
            :label="$t('user_is_list')"
            :items="userList"
            item-value="user_id"
            return-object
            item-text="user_fullname"
            outlined
            :no-data-text="$t('no_information')"
            dense
          ></v-autocomplete>
          <v-simple-table
            fixed-header
            height="420px"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    class="text-left"
                    style="width:20px"
                  >
                    #
                  </th>
                  <th class="text-left">
                    {{ $t("list") }}
                  </th>
                  <th
                    class="text-right"
                    style="width:100px"
                  >
                    {{ $t("price") }}
                  </th>
                  <th
                    class="text-center"
                    style="width:20px"
                  >
                    {{ $t("delete") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in cartList"
                  :key="index"
                >
                  <td style="width:20px">
                    {{ index + 1 }}
                  </td>
                  <td>
                    {{ item.transferorder_drug_name }}
                    <span style="color: gary">
                      {{ item.transferorder_drug_id }} EXP.
                      <ConverseDate :data="item.transferorder_drugorder_expdate" />
                      {{ item.transferorder_amount }} *
                      {{ +item.transferorder_cost / +item.transferorder_amount }}
                    </span>
                  </td>
                  <td
                    class="text-right"
                    style="width:100px"
                  >
                    {{ item.transferorder_cost | formatPrice }}
                  </td>
                  <td
                    class="text-center"
                    style="width:20px"
                  >
                    <v-tooltip
                      color="#212121"
                      top
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          color="error"
                          :loading="loadingRemove"
                          :disabled="loadingRemove"
                          v-bind="attrs"
                          @click="deleteDataDrug(item.transferorder_temp_id)"
                          v-on="on"
                        >
                          <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("delete") }}</span>
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-textarea
            v-model="transfer_comment"
            class="mt-3"
            rows="3"
            :label="`${$t('note')} ${$t('update_this_stock_cost_is_0')}`"
            outlined
            dense
          ></v-textarea>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              :loading="updateStockLoading"
              :disabled="updateStockLoading||!cartList.length"
              large
              @click="isUserComfirm = true"
            >
              {{ $t('create_list') }}
            </v-btn>
          </v-card-actions>
        </v-col>
      </v-row>
    </v-card>
    <v-dialog
      v-model.trim="isSelect"
      max-width="950"
    >
      <v-card>
        <v-card-title>
          {{ detailData.drug_name }} ({{ detailData.drug_id }})
        </v-card-title>
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    {{ $t("select") }}
                  </th>
                  <th class="text-left">
                    {{ $t("lot") }}
                  </th>
                  <th class="text-left">
                    {{ $t("expire_date") }}
                  </th>
                  <th class="text-left">
                    {{ $t("balance") }}
                  </th>
                  <th class="text-right">
                    {{ $t("cost_price") }}
                  </th>
                  <th class="text-center">
                    {{ $t("amount") }}
                  </th>
                  <th class="text-center">
                    {{ $t("unit") }}
                  </th>
                  <th class="text-right">
                    {{ $t("total") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in detailData_list"
                  :key="index"
                >
                  <td>
                    <v-checkbox
                      v-model="selectedDrug"
                      multiple
                      :value="item"
                    ></v-checkbox>
                  </td>
                  <td>
                    {{ item.druglot_name }}(
                    <ConverseDate :date="item.druglot_date" />
                    )
                  </td>
                  <td>
                    <ConverseDate :date="item.drugorder_expdate" />
                  </td>
                  <td class="text-right">
                    {{ item.drugordert_total | formatPrice }}
                  </td>
                  <td class="text-right">
                    {{ item.drugorder_cost | formatPrice }}
                  </td>
                  <td class="text-center">
                    <v-text-field
                      v-model.trim="item.transferorder_amount"
                      outlined
                      dense
                      :max="+item.drugordert_total"
                      style="width: 80px"
                      type="number"
                      hide-details
                      @input="sum( item, index)"
                    ></v-text-field>
                  </td>
                  <td>{{ item.drug_unit }}</td>
                  <td
                    class="text-right"
                    width="100px"
                  >
                    {{ (+item.transferorder_amount * item.drugorder_cost) | formatPrice }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :loading="addCardLoading"
            :disabled="!selectedDrug.length || !statusCheckCount || addCardLoading"
            @click="addDataDrug"
          >
            {{ $t("add") }}
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            @click="isSelect = false"
          >
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <UserConfirm
      v-model="isUserComfirm"
      @onSend="confirmUpdateStock"
    />
  </div>
</template>

<script>

import { mdiCheckCircleOutline, mdiDeleteOutline, mdiArrowLeftBold } from '@mdi/js'
import useCreateStockUpdate from './useCreateStockUpdate'
import DrugStatus from '@/components/basicComponents/DrugStatus.vue'
import ConverseDate from '@/components/basicComponents/ConverseDate.vue'
import { formatPrice } from '@/plugins/filters'
import UserConfirm from '@/components/basicComponents/UserConfirm.vue'

export default {
  components: {
    DrugStatus,
    ConverseDate,
    UserConfirm,

  },
  filters: {
    formatPrice,
  },

  setup() {
    return {
      ...useCreateStockUpdate(),
      icons: {
        mdiCheckCircleOutline, mdiDeleteOutline, mdiArrowLeftBold,
      },
    }
  },
}
</script>
